.processingVideoContainer {
  composes: flex-column flex-centered from global;
  flex: 1;
  height: 260px;
  background-color: var(--color-gray00);
}

.processingVideoContainer.processingBorder {
  border: 1px solid var(--color-gray10);
  border-radius: 6px;
}

.videoPreviewContainer > legend {
  display: inline-flex;
}

.uploadCaptionsLabel {
  align-items: flex-start;
  margin-left: -8px;
}

.uploadCaptionsWrapper > label {
  align-items: center;
}

.captionsContainer {
  width: 100%;
}

.lineNumbers {
  text-align: right;
  display: flex;
  flex-direction: column;
}

.clickable {
  cursor: pointer;
}

.subtitlesWrapper {
  min-width: 850px;
}

.subtitlesEditorWrapper {
  max-height: 400px;
  overflow: auto;
}

.captionsSettings > fieldset {
  padding: 0;
  width: 912px;
}

.captionsSettings > fieldset > div {
  width: 912px;
}

.captionsLightEditor pre {
  /* stylelint-disable declaration-no-important */
  background: var(--color-gray00) !important;
  color: var(--color-gray90) !important;
  border-radius: 0 !important;
  /* stylelint-enable declaration-no-important */
  white-space: pre-wrap;
}

.captionsLightEditor.disabled {
  opacity: 0.3;
}

.subtitlesEditorWrapper > pre {
  /* stylelint-disable-next-line declaration-no-important */
  padding: 0 10.5px 0 10.5px !important;
  /* stylelint-disable-next-line declaration-no-important */
  border-radius: 0 !important;
  margin: 0;
  font-size: var(--font-size-body);
  line-height: 30px;
}

.subtitlesEditorWrapper > pre > code {
  font-size: var(--font-size-body);
}

.subtitlesEditorWrapper > pre:first-child > code {
  height: 30px;
}

.subtitlesEditorWrapper > pre:last-child {
  flex: 1;
}

.subtitleError {
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--color-redFull) !important;
  font-family: Inter, sans-serif;
}

.lineNumberItem {
  position: relative;
}

.subtitlesLineError {
  position: absolute;
  z-index: 1000;
  background-color: var(--color-gray00);
  color: var(--color-gray60);
  border-radius: 4px;
  top: 26px;
  left: 45px;
  width: 691px;
  padding: 8px 16px;
  border: 1px solid var(--color-gray05);
  font-size: var(--font-size-caption);
}

.buttonsWrapper {
  width: 56px;
}

.navigationButtonDisabled {
  background-color: var(--color-gray20);
}

.navigationButtonActive > span {
  cursor: pointer;
}

.subtitlesLineError > span {
  text-align: left;
}

.subtitlesLineError > span::first-letter {
  text-transform: capitalize;
}

.subtitleErrorsNavigationButton {
  height: 24px;
  width: 24px;
}

.subtitleErrorsNavigationButton:hover {
  background-color: var(--color-gray20);
}

.errorButton {
  margin-right: 16px;
}

.errorButton > span > span {
  cursor: pointer;
  color: var(--color-brandNewPurple60);
  font-weight: 600;
}

.replace > button > span {
  cursor: pointer;
}

.alertIconWrapper {
  margin: 0 3px 0 5px;
}

.alertIconWrapper > svg {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}

.alertIconWrapper > svg > path {
  fill: var(--color-gray90);
}

.captionsEditor {
  /* stylelint-disable-next-line declaration-no-important */
  border-radius: 0 !important;
}

.modalBodyWrapper {
  display: flex;
}

.addCaptions {
  max-width: 644px;
  width: 100%;
}

.captionsFormWrapper {
  align-items: stretch;
  flex: 1;
  min-width: 640px;
}

.videoPreviewWrapper {
  flex: 1 0 50%;
  margin: auto auto 16px;
}

.captionsEditorWrapper {
  border-radius: 4px;
  border: 1px solid var(--color-gray20);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.captionsEditorWrapper > div:first-child {
  overflow: auto;
}

.captionsEditorWrapper > div:not(:first-child) {
  flex-shrink: 0;
}

.captionsSelectedFile {
  padding: 8px 16px;
  margin: 8px 0;
}

.iconButton {
  font-size: 16pt;
  margin-left: 4px;
}

.validationContainer {
  background-color: var(--color-gray10);
  padding: 16px;
  margin-top: 24px;
  width: 100%;
  min-width: 645px;
}

.validationSummary {
  margin-top: 16px;
  width: 100%;
}

.validationSummary > tr:not(:last-child) {
  border-bottom: 1px solid var(--color-gray20);
}

.validationSummary > tr > th,
.validationSummary > tr > td {
  padding: 6px 16px 6px 0;
  text-align: left;
}

.validationFilename {
  margin-left: 16px;
  background-color: #a3d3ff;
}

.validationPositive {
  background-color: #b7f29a;
}

.validationNegative {
  background-color: #f3a3a3;
}
