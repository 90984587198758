.journeyContentListDrawerOverlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-gray90);
  opacity: 0.5;
  z-index: var(--z-index-journey-drawer);
}

.journeyContentListContainer {
  width: 541px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -541px;
  background-color: var(--color-gray00);
  z-index: var(--z-index-journey-drawer);
  transition: right 0.3s ease-in-out;
  padding: 24px 24px 0 24px;
}

.journeyContentListContainer > div {
  width: 100%;
}

.journeyContentListContainer > div:first-child {
  height: 40px;
}

.journeyContentListDrawer.active .journeyContentListDrawerOverlay {
  display: block;
}

.journeyContentListDrawer.active .journeyContentListContainer {
  right: 0;
}

.journeyContentListTabs {
  margin: 8px 0 0;
  width: 100%;
}

.journeyContentList {
  flex: 1;
  margin-top: 24px;
  height: 34px;
  color: var(--color-gray50);
}

.journeyContents {
  margin-top: 16px;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

.journeyContents button {
  display: block;
  width: 100%;
  background-color: unset;
  border: none;
  cursor: inherit;
  padding: 8px 16px 16px 16px;
}

.journeyContentListItem {
  white-space: nowrap;
  border-bottom: 1px solid var(--color-gray20);
  cursor: pointer;
  position: relative;
}

.journeyContentListItem:hover {
  background-color: var(--color-gray02);
}

.journeyContentListItemDisabled {
  cursor: not-allowed;
}

.journeyContentListItemDisabled .journeyContentListItemBody {
  opacity: 0.5;
}

.journeyContentListItemDisabled:hover {
  background-color: unset;
}

.journeyContentListItemThumbnail {
  flex-shrink: 0;
  margin-right: 8px;
}

.journeyContentListItemTitle {
  overflow: hidden;
  text-align: left;
  margin-right: 8px;
  flex-grow: 1;
}

.journeyContentListItemTitle span {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.journeyContentListActions {
  align-items: flex-start;
  padding-top: 16px;
}

.journeyContentListItemInfoMessage {
  display: flex;
  color: var(--color-gray90);
  margin-top: 8px;
  text-align: left;
  font-size: 12px;
}

.journeyContentListItemInfoMessage svg {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.journeyContentListItemInfoMessage svg > path {
  fill: var(--color-blueFull);
}

.journeyContentListItemLoading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contentFilterWrapper > div:first-child {
  padding-bottom: 24px;
}

.contentListTabButton:focus {
  box-shadow: none;
}
