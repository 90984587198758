.variantNameInput {
  width: 357px;
}

.variantAudienceAlert p {
  margin: 0;
}

.variantAudienceBuilder.multi {
  margin: 0 46px 0 0;
}
