.AttributeSyncHr {
  display: flex;
  margin: 16px 0;
  column-gap: 8px;
  width: 100%;
}

.AttributeSyncHr > hr {
  flex-grow: 1;
}

.AttributeSyncIcon {
  transform: rotate(90deg);
}

.FirstupAttributeSection {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.FirstupAttributeParametersSubsection {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
