.UserMappingTableRow {
  padding: 16px 0;
}

.UserMappingRowContent {
  text-decoration: underline;
}

.UserMappingRowContent:hover {
  cursor: pointer;
  text-decoration: none;
}
