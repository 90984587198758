.wrapper {
  margin: var(--report-card-external-margin);
  padding: var(--report-card-internal-padding);
  transition: var(--insights-hover-background-change-transition-time);
  background-color: var(--color-gray02);
  border-radius: 8px;
}

.wrapper * {
  text-decoration: none;
}

.flexWrap {
  flex: 1 1 0;
}

@media (min-width: 1200px) {
  .flexWrap {
    width: 33.33%;
    min-width: 33.33%;
    max-width: 33.33%;
  }
}

@media (max-width: 1199px) {
  .flexWrap {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
  }
}

@media (max-width: 920px) {
  .flexWrap {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}

.templateData {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  padding-left: 25px;
}

.preview {
  height: 80px;
  width: 80px;
  border: none;
  cursor: pointer;
  background: transparent;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.description {
  composes: kai-line-clamp-1 from global;
  margin-top: 15px;
  font-size: 13px;
  font-weight: 600;
  color: var(--color-gray50);
}

.button {
  color: var(--color-gray80);
  font-size: 16px;
  text-align: center;
  border-radius: 2px;
  border: solid 1px transparent;
  background-color: transparent;
  transform: rotate(90deg);
  cursor: pointer;
  letter-spacing: 2px;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 150px;
  max-width: 380px;
  background-color: var(--color-gray02);
}

.colHeader {
  font-size: 9px;
  text-transform: uppercase;
  color: var(--color-gray70);
  margin-bottom: 10px;
  font-weight: 600;
}

.colData {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
  color: var(--color-gray90);
}

.title {
  width: 320px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: black;
}

.created {
  font-size: 12px;
  line-height: 15.6px;
  color: var(--color-gray50);
}

.createdAt {
  font-weight: 700;
  padding-right: 3px;
}

.createdBy > span {
  font-weight: 700;
}

.spinnerContainer {
  position: absolute;
  opacity: 0.5;
  composes: kai-flex-row from global;
  justify-content: space-around;
  width: 100%;
  width: 20px;
  height: 20px;
  margin: 0 7px;
}

.disabledOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: var(--color-gray05);
  left: 0;
  composes: kai-flex-row from global;
  justify-content: space-around;
  top: 0;
}

.spinnerContainer > div {
  height: 20px;
  width: 20px;
}

.spinnerContainer > div > svg:first-child {
  height: 20px;
  width: 20px;
}

.spinnerContainer > div > svg:last-child {
  height: 10px;
  width: 10px;
  top: 10px;
  left: 10px;
}

.status {
  width: 150px;
}

/* .workbookHeader {
  padding-bottom: 10px;
} */
.workbookCol {
  flex-direction: row;
  align-items: center;
}

.workbookHeader > div {
  padding-right: 10px;
}

.workbookList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.workbookListContainer > *:nth-child(2) {
  margin-bottom: 10px;
}

.workbookListContainer {
  align-items: flex-start;
  gap: 10px;
}

.newBadge {
  height: 20px;
  width: 42px;
  background-color: #1890ff;
  color: white;
  border-radius: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newBadge > span {
  font-size: 12px;
  font-weight: 500;
  padding: 1px 8px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--color-gray10);
  margin: 24px 0;
}
