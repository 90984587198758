.microapp {
  background-color: var(--color-gray00);
  margin: auto;
  max-width: 900px;
  width: 100%;
}

.content {
  height: 75vh;
  overflow-y: auto;
  padding: 50px;
}
