.alertContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: none;
  border-bottom: none;
  border-right: none;
}

.alertContent {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--color-gray05);
  border-bottom: 1px solid var(--color-gray05);
  border-right: 1px solid var(--color-gray05);
  flex: 1;
  width: 100%;
}

.alertBody {
  flex: 1;
}

.alertIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  margin-right: 10px;
}

.alertLink {
  text-decoration: none;
  color: black;
  font-weight: 600;
}
