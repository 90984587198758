.PreviewContainer {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.SettingsPreview {
  display: flex;
  justify-content: center;
  column-gap: 16px;
}

.SettingsPreviewItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 4px;
}

.SettingsPreviewItemSource {
  opacity: 0.8;
  font-style: italic;
}

.SettingsLastUpdatedAt {
  align-self: flex-end;
  opacity: 0.8;
  font-size: small;
  font-style: italic;
}
