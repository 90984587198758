.textField {
  font-size: var(--font-size-body);
  width: 100%;
}

.textField div[class^="user-select_option__"] {
  font-size: var(--font-size-subheading);
}

.Loading {
  text-align: center;
  width: 100%;
}

.CharacterCount {
  display: block;
  text-align: right;
  margin-top: 10px;
}

.PropertiesWrapper {
  composes: FormSubsection from "DesignSystem/Form/forms.module.css";
  composes: FormSubsectionNoDivider from "DesignSystem/Form/forms.module.css";
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.PropertiesWrapper:first-of-type {
  margin-top: 0;
}

.PropertiesWrapperWithDivider {
  composes: FormSubsection from "DesignSystem/Form/forms.module.css";
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 32px;
  padding-bottom: 32px;
}

.PropertiesLabel {
  composes: Subheading from "DesignSystem/Typography/typography.module.css";
  composes: Bold from "DesignSystem/Typography/typography.module.css";
  display: flex;
  flex: 1 1 320px;
}

.PropertiesValue {
  composes: Text from "DesignSystem/Typography/typography.module.css";
  display: flex;
  flex: 1 1 600px;
  margin: 0;
  overflow-wrap: anywhere;
}

.NoValue {
  composes: PropertiesValue;
  font-style: italic;
  color: var(--color-gray60);
  font-size: 16px;
}

.PropertiesLabelPadded {
  composes: PropertiesLabel;
  padding-top: 4px;
  padding-bottom: 4px;
}

.PropertiesLabelWellPadded {
  composes: PropertiesLabel;
  padding-top: 8px;
  padding-bottom: 8px;
}

.PropertiesValueWellPadded {
  composes: PropertiesValue;
  padding-top: 8px;
}

.PropertiesValueForPills {
  composes: PropertiesValue;
  position: relative;
  top: -8px;
}

.ImageUploadActions {
  align-items: center;
  display: flex;
  gap: 16px;
}

.ImageUploadActions > button[class^="Button_button__"] {
  font-weight: normal;
  padding-bottom: 8px;
  padding-top: 8px;
}

.ImageUploadActions > button[class^="Button_button__"]:hover {
  background-color: var(--color-gray05);
  border-color: var(--color-gray40);
  opacity: 1;
}

.ImageUploadRemove {
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--color-gray05);
}

.Avatar {
  composes: flex-column from global;
  align-items: center;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  justify-content: space-around;
}

.Avatar > svg {
  border-radius: 50%;
}

.TeamAvatar {
  composes: Avatar;
  display: inline-block;
  height: 60px;
  margin: 8px 0;
  width: 60px;
  margin-bottom: 16px;
}

.TeamAvatar > svg {
  height: 60px;
  width: 60px;
}

.UserAvatar {
  composes: Avatar;
  height: 40px;
  margin-right: 16px;
  position: relative;
  top: -8px;
  width: 40px;
}

.UserAvatar > svg {
  height: 40px;
  width: 40px;
}

.PillAvatar {
  composes: Avatar;
  display: inline-block;
  height: 24px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 24px;
}

.PillAvatar > svg {
  height: 24px;
  width: 24px;
}

.PillText {
  margin-left: 11px;
}

.modalHeader {
  height: 72px;
  padding: 16px 24px;
  font-size: var(--font-size-heading);
  line-height: 150%;
  font-weight: 600;
}

.modalBody {
  border-top: 1px solid var(--color-gray05);
  border-bottom: 1px solid var(--color-gray05);
  min-height: 71px;
  padding: 24px;
  color: var(--color-gray60);
  font-size: var(--font-size-body);
}

.modalFooter {
  width: 100%;
  padding: 16px 24px;
}

.modalWrapper {
  width: 500px;
}

.modalWrapper > div {
  width: 100%;
}

.modalWrapper button {
  margin-left: 8px;
}

.warningIcon {
  margin-right: 8px;
}
