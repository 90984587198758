.variantContainer {
  background-color: var(--color-gray10);
  padding: 8px;
}

.variantContent {
  align-items: start;
}

.variantSelected,
.variantSelectable:hover {
  outline-style: solid;
  outline-color: var(--color-brandShadeDark);
}

.variantSelected {
  outline-width: 1px;
}

.variantSelectable:hover {
  outline-width: 2px;
}

.variantDefault {
  width: 100%;
  align-items: start;
}

.dragging {
  opacity: 0;
}

.variantRow {
  width: 100%;
}

.variantCount {
  margin-left: 4px;
}

.handle {
  cursor: move;
}

.action {
  cursor: pointer;
  margin-left: 4px;
}

.audienceIcon {
  font-size: 1rem;
}
