.SectionsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

.ConfigurationSectionBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  width: 100%;
}

.ConfigurationSectionHeading {
  margin-bottom: 16px;
}

.CtaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  row-gap: 24px;
}

.AdministrativeAction {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;
}
