.iconPadding {
  padding-left: 4px;
}

.variantBadge {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  font-size: 12pt;
  color: #fff;
  text-align: center;
  background: #1890ff;
  margin-right: 4px;
}

.variantRow {
  width: 100%;
  margin-top: 8px;
}
