.numberCard {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  column-gap: 8px;
  flex-grow: 1;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 16px;
}

.compactStepCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  column-gap: 8px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 16px;
  flex: 0 0 auto;
}

.compactStepCard__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  width: 100%;
}

.compactStepCard__body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
  padding: 0;
  margin: 0;
  flex-grow: 1;
}

.compactStepCard__percentage_pill {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  height: 29px;
  padding: 8px;
}

.channelDetailCard {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid #ccc;
  border-radius: 16px;
  column-gap: 8px;
  flex: 1;
}

.channelDetailCard__header,
.channelDetailCard__content,
.channelDetailCard__footer {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.channelDetailCard__header_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
}

.channelDetailCard__list_item {
  display: flex;
  flex-direction: column;
}

.channelDetailCard__list_item_body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: nowrap;
  flex-grow: 1;
  padding: 8px 0;
  border-bottom: 1px solid #e6e6e6;
}

.channelDetailCard__list_item:last-child .channelDetailCard__list_item_body {
  margin-top: -1px;
  border-top: 1px solid #e6e6e6;
  border-bottom: none;
}

.channelDetailCard__list_item ul {
  margin-left: 16px;
}

.channelDetailCard__list_item_tooltip_icon {
  font-size: 16px;
  vertical-align: middle;
}
